import React, { useState, useEffect } from "react";

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    console.log("first");

    return () => clearInterval(timer);
  }, [targetDate]); // Update timer if targetDate changes

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const { days, hours, minutes } = timeLeft;

  return (
    <div>
      {days !== undefined ? (
        <span>{`${days}d ${formatTime(hours)}h ${formatTime(minutes)}m`}</span>
      ) : (
        <span>Expired</span>
      )}
    </div>
  );
};

export default CountdownTimer;
