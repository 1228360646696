import React, { useState } from "react";
import ProgressBar from "./ProgressBarComp";

export default function Assessment() {
  const [voice, setVoice] = useState(0);

  function handleVoice() {
    setVoice(!voice);
  }
  const [video, setVideo] = useState(0);

  function handleVideo() {
    setVideo(!video);
  }
  return (
    <div>
      <header>
        <div className="w-full md:px-10 px-6 py-4">
          <div className="flex items-center gap-1">
            <img src="logo.svg" alt="" className="" />
            <h1 className="text-4xl font-semibold">Novus</h1>
          </div>
        </div>
      </header>
      <div className="flex w-full">
        <ProgressBar />
        {/* <div className="w-[55%] bg-[#2776F9] h-4"></div>
        <div className="w-[45%] bg-[#7787A6] h-4"></div> */}
      </div>
      <div className="flex lg:flex-row flex-col gap-4 md:px-10 px-6 mt-4 min-[1100px]:mb-0 mb-10 min-[1100px]:h-[80vh]">
        <div className="lg:w-[65%] rounded-xl bg-[#0E1329] flex flex-col p-8 relative min-[1100px]:justify-between">
          <img
            src="/Ellipse 15.png"
            alt=""
            width={600}
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />

          <div className="flex flex-col gap-1 py-3 pl-4 pr-6 bg-[#131B36] w-fit rounded-md relative self-end">
            <img src="/piece.svg" alt="" className="absolute top-0 -right-4" />
            <p className="text-sm">Current Status</p>
            <h1 className="md:text-4xl text-2xl font-semibold">IQ Test</h1>
          </div>
          <div className="flex justify-center min-[1100px]:mt-0 mt-16 z-10">
            <img src="/robot.png" alt="" width={370} />
          </div>
          <div className="flex gap-2 z-50 items-center justify-center min-[1100px]:mt-0 md:mt-28 mt-12 lg:-ml-[35%] md:-ml-[20%] min-[1260px]:-ml-12">
            <div
              className="bg-[#1E2748] w-12 h-12 flex items-center justify-center rounded-[5px] cursor-pointer"
              onClick={handleVoice}
            >
              {voice ? (
                <img src="/voice.svg" alt="" />
              ) : (
                <img src="/voice2.svg" alt="" />
              )}
            </div>
            <div
              className="bg-[#1E2748] w-12 h-12 flex items-center justify-center rounded-[5px] cursor-pointer"
              onClick={handleVideo}
            >
              {video ? (
                <img src="/video.svg" alt="" width={30} />
              ) : (
                <img src="/video2.svg" alt="" width={30} />
              )}
            </div>
          </div>
          <div className="bg-[#1E2748] rounded-lg p-2 w-fit m-auto md:mt-0 mt-6 md:absolute bottom-6 right-6 cursor-pointer z-50">
            <img src="/image 6.png" alt="" width={300} />
          </div>
        </div>
        <div className="lg:w-[35%] rounded-xl flex flex-col bg-[#0E1329] p-4">
          <div className="min-[1100px]:h-full h-[680px] mb-4 overflow-y-scroll overflow-x-hidden cstm-scroll">
            <div className="h-full cstm-scroll flex flex-col gap-4 px-4 py-4 sm:text-base text-sm">
              <div className="bg-[#1E71FB] px-6 py-3 min-[1111px]:w-[20rem] self-end rounded-[5px] relative">
                <img
                  src="/piece2.svg"
                  alt=""
                  className="absolute top-0 -right-3"
                />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              </div>
              <div className="bg-[#131B36] text-[#7787A6] px-6 py-3 min-[1111px]:w-[23rem] rounded-[5px] relative">
                <img
                  src="/piece.svg"
                  alt=""
                  className="absolute top-0 -left-3 rotate-90"
                />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam,
              </div>
              <div className="bg-[#1E71FB] px-6 py-3 min-[1111px]:w-[20rem] self-end rounded-[5px] relative">
                <img
                  src="/piece2.svg"
                  alt=""
                  className="absolute top-0 -right-3"
                />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              </div>
              <div className="bg-[#131B36] text-[#7787A6] px-6 py-3 min-[1111px]:w-[23rem] rounded-[5px] relative">
                <img
                  src="/piece.svg"
                  alt=""
                  className="absolute top-0 -left-3 rotate-90"
                />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam,
              </div>
              <div className="bg-[#1E71FB] px-6 py-3 min-[1111px]:w-[20rem] self-end rounded-[5px] relative">
                <img
                  src="/piece2.svg"
                  alt=""
                  className="absolute top-0 -right-3"
                />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              </div>
              <div className="bg-[#131B36] text-[#7787A6] px-6 py-3 min-[1111px]:w-[23rem] rounded-[5px] relative">
                <img
                  src="/piece.svg"
                  alt=""
                  className="absolute top-0 -left-3 rotate-90"
                />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam,
              </div>
              <div className="bg-[#1E71FB] px-6 py-3 min-[1111px]:w-[20rem] self-end rounded-[5px] relative">
                <img
                  src="/piece2.svg"
                  alt=""
                  className="absolute top-0 -right-3"
                />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              </div>
              <div className="bg-[#131B36] text-[#7787A6] px-6 py-3 min-[1111px]:w-[23rem] rounded-[5px] relative">
                <img
                  src="/piece.svg"
                  alt=""
                  className="absolute top-0 -left-3 rotate-90"
                />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam,
              </div>
              <div className="bg-[#1E71FB] px-6 py-3 min-[1111px]:w-[20rem] self-end rounded-[5px] relative">
                <img
                  src="/piece2.svg"
                  alt=""
                  className="absolute top-0 -right-3"
                />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              </div>
              <div className="bg-[#131B36] text-[#7787A6] px-6 py-3 min-[1111px]:w-[23rem] rounded-[5px] relative">
                <img
                  src="/piece.svg"
                  alt=""
                  className="absolute top-0 -left-3 rotate-90"
                />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam,
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            <input
              type="text"
              name=""
              id=""
              className="bg-[#1E2748] rounded-[10px] w-full py-4 px-6 focus-visible:outline-none"
              placeholder="Type text"
            />
            <button className="cstm-btn rounded-md p-4">
              <img src="/send.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
