import React, { useState } from "react";
import SignupPopUp from "./SignupPopup";

export default function LoginPopup({ HandleSigninClose }) {
  return (
    <div className="h-screen fixed inset-0 backdrop-blur-lg	flex justify-center items-center">
      <div className="sm:w-[480px]">
        <div className="flex justify-between bg-[#2776F9] sm:py-4 py-2 sm:px-8 px-6">
          <h1 className="sm:text-2xl text-xl font-semibold">Login</h1>
          <img
            src="/close.svg"
            alt=""
            width="15"
            className="cursor-pointer"
            onClick={HandleSigninClose.HandleSigninClose}
          />
        </div>
        <div className="bg-[#040A20]">
          <div className="sm:pt-12 pt-6 sm:pb-8 pb-6 sm:px-8 px-6 flex flex-col gap-5">
            <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
              <img
                src="/user.svg"
                alt=""
                width="20"
                className="sm:w-[20px] w-[15px]"
              />
              <input
                type="text"
                name=""
                id=""
                placeholder="Username"
                className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
              />
            </div>
            <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
              <img
                src="/pass.svg"
                alt=""
                width="20"
                className="sm:w-[20px] w-[15px]"
              />
              <input
                type="password"
                name=""
                id=""
                placeholder="Password"
                className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
              />
            </div>
            <button className="cstm-btn rounded-[5px] px-6 py-2 sm:text-base text-sm">
              Login
            </button>
            <h2 className="sm:text-sm text-xs -mt-2 cursor-pointer">
              Forgot Password?
            </h2>
          </div>
          <div className="h-[2px] bg-[#898989] flex items-center justify-center">
            <p className="bg-[#040A20] w-fit px-2 text-sm">OR</p>
          </div>
          <div className="sm:px-8 px-6 sm:pt-12 pt-6 sm:pb-16 pb-6">
            <button
              className="w-full bg-white text-black rounded-[5px] px-6 py-2 sm:text-base text-sm"
              onClick={HandleSigninClose.HandleSignupOpen2}
            >
              Create an Account
            </button>
          </div>
        </div>
        {/* <SignupPopUp /> */}
        {/* {SignupPopup && <SignupPopUp HandleSignupClose={HandleSignupClose} />} */}
      </div>
    </div>
  );
}
