import React, { useState, useEffect } from "react";
import ProgressBar from "@ramonak/react-progress-bar";

export default function ProgressBarComp() {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount + 2);
    }, 1000); // Increment every 1000ms (1 second)

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);
  return (
    <div className="w-full">
      <ProgressBar
        completed={count}
        bgColor="#2776F9"
        height="15px"
        isLabelVisible={false}
        baseBgColor="#7787A6"
        labelColor="#e80909"
        labelSize=""
      />
    </div>
  );
}
