import "./App.css";
import Dashboard from "./components/Dashboard";
import Assessment from "./components/Assessment";
import Hero from "./components/Hero";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function App() {
  return (
    <Router>
      <div className="App text-white">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Novus</title>
        </Helmet>
        <Routes>
          <Route path="/" element={<Hero />}></Route>
          <Route path="/Dashboard" element={<Dashboard />}></Route>
          <Route path="/Assessment" element={<Assessment />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
