import React, { useState } from "react";
import LoginPopup from "./LoginPopup";
import SignupPopUp from "./SignupPopup";
import WorldIdPopup from "./WorldIdPopup";

export default function Hero() {
  const [SigninPopup, SetSigninPopup] = useState(false);

  function HandleSigninOpen() {
    // console.log("open");
    SetSigninPopup(true);
  }
  function HandleSigninClose() {
    // console.log("close");
    SetSigninPopup(false);
  }

  const [SignupPopup, SetSignupPopup] = useState(false);

  function HandleSignupOpen() {
    SetSignupPopup(true);
  }
  function HandleSignupClose() {
    SetSignupPopup(false);
  }
  function HandleSignupOpen2() {
    SetSignupPopup(true);
    SetSigninPopup(false);
  }
  return (
    <div className="">
      <header>
        <div className="w-full md:px-16 px-6 py-4">
          <div className="flex items-center gap-1">
            <img src="logo.svg" alt="" className="" />
            <h1 className="text-4xl font-semibold">Novus</h1>
          </div>
        </div>
      </header>
      <div className="relative">
        <div className="flex md:flex-row flex-col cstm-div">
          {/* <div className="flex md:flex-row flex-col max-w-[95rem] m-auto"> */}
          <div className="md:w-[60%] md:py-20 py-12 md:px-6 px-10 md:max-w-xl m-auto">
            <div className="flex flex-col items-start gap-6">
              <img src="logo2.svg" alt="" className="" width="90" />
              <h1 className="md:text-6xl text-4xl md:leading-[4rem] md:pr-20">
                Unlock Your Potential and Earn Crypto Rewards!
              </h1>
              <p className="md:text-base text-sm">
                Join our community, complete engaging interviews, and get
                rewarded in cryptocurrency for your skills and insights.
              </p>
              <div className="flex gap-2 items-center">
                <button
                  className="bg-gradient-to-br from-[#77AAFF] to-[#2776F9] rounded-[5px] px-6 py-2"
                  onClick={HandleSigninOpen}
                >
                  Login
                </button>
                <button
                  className="bg-transparent border border-white rounded-[5px] md:px-6 px-4 py-2"
                  onClick={HandleSignupOpen}
                >
                  Create an Account
                </button>
              </div>
              <div className="flex gap-2 items-start mt-2">
                <a href="">
                  <img src="f.svg" alt="" className="" width="40" />
                </a>
                <a href="">
                  <img src="x.svg" alt="" className="" width="40" />
                </a>
                <a href="">
                  <img src="t.svg" alt="" className="" width="40" />
                </a>
              </div>
            </div>
          </div>
          <div className="md:w-1/2 flex justify-end bg-cstm bg-no-repeat bg-cover md:h-auto h-[500px] border-l border-[#04C8EC] cstm-brdr">
            {/* <img src="/Mask group.png" alt="" className="md:absolute right-0 top-0 lg:h-[800px] md:h-[700px] lg:w-[600px] md:w-[350px]" /> */}
          </div>
        </div>
      </div>
      {SigninPopup && <LoginPopup HandleSigninClose={{HandleSigninClose, HandleSignupOpen2}} />}
      {SignupPopup && <SignupPopUp HandleSignupClose={HandleSignupClose} />}
      {/* <WorldIdPopup /> */}
    </div>
  );
}
