import React from "react";

export default function SignupPopUp({ HandleSignupClose }) {
  return (
    <div className="h-screen fixed inset-0 backdrop-blur-lg	flex justify-center items-center">
      <div className="sm:w-[480px] w-[300px]">
        <div className="flex justify-between bg-[#2776F9] sm:py-4 py-2 sm:px-8 px-6">
          <h1 className="sm:text-2xl text-xl font-semibold">
            Create an Account
          </h1>
          <img
            src="/close.svg"
            alt=""
            width="15"
            className="cursor-pointer"
            onClick={HandleSignupClose}
          />
        </div>
        <div className="bg-[#040A20]">
          <div className="sm:py-12 py-6 sm:px-8 px-6 flex flex-col gap-5">
            <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
              <input
                type="text"
                name=""
                id=""
                placeholder="First Name"
                className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
              />
            </div>
            <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
              <input
                type="text"
                name=""
                id=""
                placeholder="Last Name"
                className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
              />
            </div>
            <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
              <input
                type="text"
                name=""
                id=""
                placeholder="Email Address"
                className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
              />
            </div>
            <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
              <input
                type="password"
                name=""
                id=""
                placeholder="Password"
                className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
              />
            </div>
            <div className="bg-[#1E2748] sm:py-3 py-2 sm:px-4 px-2 flex items-center gap-2 rounded-[5px]">
              <input
                type="password"
                name=""
                id=""
                placeholder="Confirm Password"
                className="w-full bg-transparent focus-visible:outline-none placeholder:text-[#7787A6] sm:text-base text-sm"
              />
            </div>
            <button className="cstm-btn rounded-[5px] px-6 py-3 md:mt-4 sm:text-base text-sm">
              SignUp
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
